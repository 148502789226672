
import { defineComponent } from "vue";
import { useMeta } from "vue-meta";

import NavigatorCommon from "@/components/navigation/Container.vue";

import { Navigation, ResponsiveManager } from "@/controllers/common";
import Authentication from "./controllers/auth";
import TFAForm from "@/components/auth/TFAuth.vue";
import TwoFactorAuth from "./controllers/tfa-auth";
import Channels from "./controllers/pusher";
import { FirebaseUser } from "./models/users";
import { AuthorizationState, TFAData } from "./models/auth";

export default defineComponent({
  components: {
    NavigatorCommon,
    TFAForm,
  },
  setup() {
    useMeta({
      title: "",
      description:
        "Somos un Centro de Adoración a Dios, un Centro de Revelación de la gloria de Dios, un Centro de Demostración del Poder de Dios y un Centro de Expansión del Reino de Dios.",
      htmlAttrs: { lang: "es", amp: true },
    });
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", ResponsiveManager.storeScreenSize);
    });
  },
  data() {
    return {
      outcastRoute: false,
      actionRoute: false,
      showModal: false,
      tfaLoading: false,
      tfaReset: false,
      pusher: null as Channels | null,
    };
  },
  watch: {
    // eslint-disable-next-line
    $route(to, from) {
      this.outcastRoute =
        (this.$route.meta.isError as boolean) ??
        (this.$route.meta.isAuthAction as boolean) ??
        false;
    },
    isAuthenticated: function () {
      if (this.isAuthenticated != null) {
        if (this.isAuthenticated) {
          this.pusher = new Channels();
          TwoFactorAuth.addAuthListener(this.pusher as Channels, this.userId);
        } else {
          TwoFactorAuth.closeConnection(this.pusher as Channels);
          this.pusher = null;
        }
      }
    },
    isVerified: function () {
      if (this.isVerified != null && this.isAuthenticated) {
        if (!this.isVerified) {
          this.showModal = true;
        }
      }
    },
    authorizationState: function () {
      if (!this.outcastRoute) {
        switch (this.authorizationState) {
          case AuthorizationState.NO_DATA:
            Navigation.goTo("Login");
            break;
          case AuthorizationState.INCOMPLETE:
            Navigation.goTo("Login");
            break;
          case AuthorizationState.AUTHORIZED:
            Navigation.goTo(Navigation.pendingRoute ?? "Profile");
            break;
          case AuthorizationState.NOT_AUTHORIZED:
            Navigation.goTo("Login");
            break;
        }
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", ResponsiveManager.storeScreenSize);
  },
  computed: {
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
    userId(): string {
      return this.currentUser?.uid ?? "";
    },
    isAuthenticated(): boolean | null {
      return Authentication.isAuthenticated();
    },
    isVerified(): boolean | null {
      return Authentication.isVerified();
    },
    authorizationState(): AuthorizationState {
      return Authentication.authorizationState();
    },
    isAuthorized(): boolean {
      return this.authorizationState === AuthorizationState.AUTHORIZED;
    },
    isInitializing(): boolean {
      return this.authorizationState === AuthorizationState.NO_DATA;
    },
  },
  methods: {
    tfaCleared(): void {
      this.tfaReset = false;
      this.tfaLoading = false;
    },
    async verifyToken(data: TFAData): Promise<void> {
      this.tfaLoading = true;
      const result = await TwoFactorAuth.verify(data);

      if (result) {
        this.tfaLoading = false;
        this.showModal = false;
      } else {
        this.tfaReset = true;
      }
    },
    async reset2FA(): Promise<void> {
      this.tfaLoading = true;
      const email = this.currentUser?.email ?? "";
      const result = await TwoFactorAuth.reset2FA(email);
      this.tfaLoading = false;
      this.showModal = !result;
    },
  },
});
