<template>
  <ul class="mobile-nav">
    <li>
      <div
        class="menu no-select"
        :class="{ opened: state.opened }"
        @click="isMenuOpen = !isMenuOpen"
      >
        <div id="bar1"></div>
        <div id="bar2"></div>
        <div id="bar3"></div>
      </div>
    </li>
    <li>
      <router-link :to="{ name: 'Profile' }">
        <img src="@/assets/common/cc-logo.svg" alt="Inicio" />
      </router-link>
    </li>
    <li>
      <div class="action-button" @click="logout">
        <img src="@/assets/actions/logout-icon.svg" alt="Salir" />
      </div>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { NavState } from "@/models/common";
import FirebaseAuth from "@/controllers/firebase-auth";

export default defineComponent({
  name: "NavigatorMobile",
  props: {
    modelValue: {
      type: Boolean,
    },
    state: {
      type: Object as PropType<NavState>,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    async logout() {
      await FirebaseAuth.signOut();
    },
  },
  computed: {
    isMenuOpen: {
      get(): boolean {
        return this.modelValue;
      },
      set(value: boolean): void {
        this.$emit("update:modelValue", value);
      },
    },
  },
});
</script>

<style scoped>
#bar1 {
  transform-origin: top left;
}
#bar2 {
  margin: 4.75px 0;
}
#bar3 {
  transform-origin: bottom left;
}
.opened #bar1 {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.opened #bar2 {
  opacity: 0;
}
.opened #bar3 {
  height: 1.5px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.mobile-nav {
  height: 50px;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  margin: 0;
}
.mobile-nav li {
  align-items: center;
  display: flex;
}
.menu {
  display: inline-block;
  cursor: pointer;
  padding: 5px 3px;
}
.menu div {
  width: 18px;
  height: 1.5px;
  background-color: var(--c-eighty-white);
  transition: 0.4s ease-in-out;
}
.action-button {
  --button-size: 24px;
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .mobile-nav {
    height: calc(100% - 50px);
    width: var(--nav-tablet-width);
    padding: 25px 0;
    flex-direction: column;
    align-items: center;
    display: flex;
  }
}
</style>
