
import { defineComponent, PropType } from "vue";

import NavigatorItem from "@/components/navigation/NavigatorItem.vue";

import { NavState, ScreenType } from "@/models/common";
import FirebaseAuth from "@/controllers/firebase-auth";
import { Clearance, UserData } from "@/models/users";
import { ClearanceController } from "@/controllers/clearance";
export default defineComponent({
  name: "AdminNavigator",
  components: {
    NavigatorItem,
  },
  props: {
    currentUser: {
      type: Object as PropType<UserData>,
      required: true,
    },
    state: {
      type: Object as PropType<NavState>,
      required: true,
    },
  },
  methods: {
    async logout() {
      await FirebaseAuth.signOut();
    },
  },
  computed: {
    openMenu(): boolean {
      if (this.state.device != ScreenType.largeDesktop) {
        return this.state.opened;
      }
      return false;
    },
    isMobile(): boolean {
      return this.state.device === ScreenType.mobile;
    },
    isDesktop(): boolean {
      return (
        this.state.device === ScreenType.smallDesktop ||
        this.state.device === ScreenType.largeDesktop
      );
    },
    fullname(): string {
      return `${this.currentUser.name} ${this.currentUser.lastname}`;
    },
    pictureUrl(): string {
      return this.currentUser.pictureUrl
        ? this.currentUser.pictureUrl
        : require("@/assets/common/avatar-placeholder.png");
    },
    clearanceText(): string {
      return ClearanceController.text(this.currentUser.role);
    },
    clearance(): Record<string, boolean> {
      return {
        registered: ClearanceController.validateClearance(Clearance.Registered),
        finance: ClearanceController.validateClearance(Clearance.Finance),
        editor: ClearanceController.validateClearance(Clearance.Editor),
        manager: ClearanceController.validateClearance(Clearance.Manager),
        admin: ClearanceController.validateClearance(Clearance.Admin),
      };
    },
  },
});
