<template>
  <button
    class="btn-container"
    :class="{ small: isSmall, big: !isSmall }"
    :disabled="disabled"
  >
    <span class="btn-text">{{ text }}</span>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "BaseButton",
  props: {
    text: {
      type: String,
      required: true,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped>
.btn-container {
  cursor: pointer;
  font-size: 1rem;
  height: fit-content;
  border: none;
  background: var(--c-harvest-gold);
  color: white;
}
.btn-container.small {
  padding: 0.5em 2.8em;
  border-radius: 30px;
  display: inline-block;
}
.btn-container.big {
  padding: 1.3em;
  border-radius: 8px;
}
.btn-container:focus {
  outline: none;
}
.btn-container:hover,
.btn-container:disabled {
  background: var(--c-harvest-gold-light);
}
.btn-container:disabled {
  cursor: auto;
}
.btn-container:enabled:active {
  background: var(--c-harvest-gold-dark);
}
.btn-container.small .btn-text {
  font-family: "Roboto", sans-serif;
  font-weight: var(--f-regular);
  font-size: 1.4em;
}
.btn-container.big .btn-text {
  font-family: "Montserrat", sans-serif;
  font-weight: var(--f-semibold);
  font-size: 1.8em;
}
</style>
