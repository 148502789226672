
import { defineComponent, PropType } from "vue";

import NavigatorMobile from "@/components/navigation/Mobile.vue";
import NavigatorAdmin from "@/components/navigation/Admin.vue";

import { NavState, ScreenType } from "@/models/common";
import { ResponsiveManager } from "@/controllers/common";
import { FirebaseUser, UserData } from "@/models/users";
import { ClearanceController } from "@/controllers/clearance";

export default defineComponent({
  name: "NavigatorCommon",
  components: {
    NavigatorMobile,
    NavigatorAdmin,
  },
  props: {
    currentUser: {
      type: Object as PropType<FirebaseUser>,
      default: null,
    },
  },
  mounted() {
    if (this.currentUser) {
      const displayName = this.currentUser?.displayName?.split(";") ?? ["", ""];
      this.userData.name = displayName[0];
      this.userData.lastname = displayName[1];
      this.userData.email = this.currentUser?.email ?? "";
      this.userData.phone = +(this.currentUser?.phoneNumber ?? "").replace(
        "57",
        ""
      );
      this.userData.pictureUrl = this.currentUser?.photoURL ?? "";
      this.getCurrentRole(this.currentUser);
    }
  },
  data() {
    return {
      userData: {} as UserData,
      isMenuOpen: false,
    };
  },
  watch: {
    // eslint-disable-next-line
    $route(to, from) {
      this.isMenuOpen = false;
    },
    showMobile: function () {
      if (!this.showMobile) {
        this.isMenuOpen = false;
      }
    },
  },
  computed: {
    showMobile(): boolean {
      return ResponsiveManager.retrieveScreenSize() != ScreenType.largeDesktop;
    },
    state(): NavState {
      return {
        opened: this.isMenuOpen,
        device: ResponsiveManager.retrieveScreenSize(),
      };
    },
  },
  methods: {
    async getCurrentRole(currentUser: FirebaseUser) {
      if (currentUser) {
        this.userData.role = ClearanceController.granted;
      }
    },
  },
});
