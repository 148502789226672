<template>
  <div class="admin-nav" :class="{ opened: openMenu }">
    <div class="action-button logout" @click="logout" v-if="!isMobile">
      <img src="@/assets/actions/logout-icon.svg" alt="Salir" />
    </div>
    <div class="profile">
      <img :src="pictureUrl" alt="Nombre" class="user-avatar" />
      <h2 class="name">{{ fullname }}</h2>
      <h3 class="position">{{ clearanceText }}</h3>
    </div>
    <ul class="admin-menu">
      <li class="category-title">Mi Cuenta</li>
      <NavigatorItem
        title="Actualizar Datos"
        target="Profile"
        :isDesktop="true"
      />
      <li class="category-title" v-if="clearance.admin">Usuarios</li>
      <NavigatorItem
        title="Gestionar Usuarios"
        target="Users"
        :isDesktop="true"
        v-if="clearance.admin"
      />
      <li class="category-title" v-if="clearance.editor">General</li>
      <NavigatorItem
        title="Banners"
        target="Banners"
        :isDesktop="true"
        v-if="clearance.editor"
      />
      <NavigatorItem
        title="Eventos"
        target="Events"
        :isDesktop="true"
        v-if="clearance.editor"
      />
      <NavigatorItem
        title="Verso Donaciones"
        target="Verse"
        :isDesktop="true"
        v-if="clearance.editor"
      />
      <li class="category-title" v-if="clearance.finance">Finanzas</li>
      <NavigatorItem
        title="Donaciones"
        target="Donations"
        :isDesktop="true"
        v-if="clearance.finance"
      />
      <li class="category-title" v-if="clearance.editor">Misiones</li>
      <NavigatorItem
        title="Proyectos"
        target="Missions"
        :isDesktop="true"
        v-if="clearance.editor"
      />
      <li class="category-title" v-if="clearance.editor">Series del Pastor</li>
      <NavigatorItem
        title="Destacadas"
        target="Series"
        :isDesktop="true"
        v-if="clearance.editor"
      />
      <li class="category-title" v-if="clearance.editor">Encuéntranos</li>
      <NavigatorItem
        title="Sedes"
        target="Venues"
        :isDesktop="true"
        v-if="clearance.editor"
      />
      <NavigatorItem
        title="Mensajes"
        target="Messages"
        :isDesktop="true"
        v-if="clearance.manager"
      />
    </ul>
    <div class="cc-logo" v-if="isDesktop">
      <img src="@/assets/common/cc-logo.svg" alt="Iglesia Centro Cristiano" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import NavigatorItem from "@/components/navigation/NavigatorItem.vue";

import { NavState, ScreenType } from "@/models/common";
import FirebaseAuth from "@/controllers/firebase-auth";
import { Clearance, UserData } from "@/models/users";
import { ClearanceController } from "@/controllers/clearance";
export default defineComponent({
  name: "AdminNavigator",
  components: {
    NavigatorItem,
  },
  props: {
    currentUser: {
      type: Object as PropType<UserData>,
      required: true,
    },
    state: {
      type: Object as PropType<NavState>,
      required: true,
    },
  },
  methods: {
    async logout() {
      await FirebaseAuth.signOut();
    },
  },
  computed: {
    openMenu(): boolean {
      if (this.state.device != ScreenType.largeDesktop) {
        return this.state.opened;
      }
      return false;
    },
    isMobile(): boolean {
      return this.state.device === ScreenType.mobile;
    },
    isDesktop(): boolean {
      return (
        this.state.device === ScreenType.smallDesktop ||
        this.state.device === ScreenType.largeDesktop
      );
    },
    fullname(): string {
      return `${this.currentUser.name} ${this.currentUser.lastname}`;
    },
    pictureUrl(): string {
      return this.currentUser.pictureUrl
        ? this.currentUser.pictureUrl
        : require("@/assets/common/avatar-placeholder.png");
    },
    clearanceText(): string {
      return ClearanceController.text(this.currentUser.role);
    },
    clearance(): Record<string, boolean> {
      return {
        registered: ClearanceController.validateClearance(Clearance.Registered),
        finance: ClearanceController.validateClearance(Clearance.Finance),
        editor: ClearanceController.validateClearance(Clearance.Editor),
        manager: ClearanceController.validateClearance(Clearance.Manager),
        admin: ClearanceController.validateClearance(Clearance.Admin),
      };
    },
  },
});
</script>

<style scoped>
.admin-nav {
  font-size: 1rem;
}
.action-button {
  --button-size: 24px;
  top: 1.8rem;
  position: absolute;
}
.action-button.home {
  left: 2.4rem;
}
.action-button.logout {
  right: 2.4rem;
}
.profile {
  margin-top: 4.2rem;
}
.user-avatar {
  --avatar-size: 12rem;
}
.profile .name {
  font-size: 1.6em;
  font-weight: var(--f-medium);
  color: #fff;
  margin: 0;
  margin-top: 1.6rem;
}
.profile .position {
  font-size: 1.3em;
  font-weight: var(--f-light);
  color: var(--c-silver-chalice);
  margin: 0;
}
.admin-menu {
  text-align: start;
  font-size: 1.4em;
  list-style: none;
}
.category-title {
  font-weight: var(--f-semibold);
  color: var(--c-eighty-white);
  margin-top: 1.2rem;
}

.cc-logo {
  opacity: 0.5;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
}

.cc-logo img {
  height: 3rem;
  margin: 2rem 0;
}
@media only screen and (max-width: 767px) {
  .admin-nav {
    border-top: 1px solid var(--c-twenty-white);
    margin: 0;
    transform-origin: top;
    transform: scaleY(0);
    opacity: 0;
    transition: 0.3s ease-in-out;
    overflow-y: scroll;
    max-height: calc(100vh - 50px);
  }
  .admin-nav.opened {
    transform: scaleY(1);
    opacity: 1;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .admin-nav {
    transform-origin: left;
    transform: scaleX(0);
    width: 0;
    opacity: 0;
    transition: 0.3s ease-in-out;
  }
  .admin-nav.opened {
    transform: scaleX(1);
    width: 350px;
    opacity: 1;
  }
}
</style>
