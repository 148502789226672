import { TFAData } from "@/models/auth";
import PopUpMessage, { NotificationType } from "@/models/popup";
import { TFAEvent } from "@/models/pusher";
import axios, { AxiosError } from "axios";
import { Store } from "./common";
import Channels from "./pusher";
import ServiceController from "./service";

export default class TwoFactorAuth extends ServiceController {
  static async verify(data: TFAData): Promise<boolean> {
    try {
      await axios.post(`${TwoFactorAuth.HOST}/2tfa/check`, data);
      return true;
    } catch (error) {
      const notification = new PopUpMessage({
        title: "Código Inválido",
        message:
          "Intentalo de nuevo, si el problema persiste contactate con soporte.",
        type: NotificationType.Error,
      });
      notification.show();
      return false;
    }
  }

  static async reset2FA(email: string): Promise<boolean> {
    try {
      await axios.post(`${TwoFactorAuth.HOST}/users/reset-a2f`, {
        email: email,
      });
      const notification = new PopUpMessage({
        title: "Éxito",
        message: "Se enviaron los nuevos datos a tu correo electrónico.",
        type: NotificationType.Success,
      });
      notification.show();
      return true;
    } catch (error) {
      const axiosError = error as AxiosError;
      TwoFactorAuth.translateError(axiosError);
      return false;
    }
  }

  static addAuthListener(channels: Channels, uid: string): void {
    try {
      /* channels.addAuthListener(
        { channel: "2fa-channel", event: uid },
        function (data: TFAEvent) {
          
          Store.update("auth/change2FA", data.authorized);
        }
      );
      setTimeout(async function () {
        await axios.post(`${TwoFactorAuth.HOST}/2tfa/initialization`, {
          uid: uid,
        });
      }, 1000);
      setTimeout(function () {
        const tfaState = Store.getValue("auth/isVerified");
        if (!tfaState) {
          Store.update("auth/change2FA", false);
        }
      }, 5000); */
      Store.update("auth/change2FA", true);
      // eslint-disable-next-line
    } catch (error: any) {
      const notification = new PopUpMessage({
        title: error.code,
        message: error.message,
        type: NotificationType.Error,
      });
      notification.show();
    }
  }

  static closeConnection(channels: Channels): void {
    if (channels) {
      channels.disconnect();
    }
    Store.update("auth/change2FA", null);
  }
}
