import Authentication from "@/controllers/auth";
import { Navigation } from "@/controllers/common";
import { ClearanceController } from "@/controllers/clearance";
import { Clearance } from "@/models/users";
import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import { AuthorizationState } from "@/models/auth";

const routes: Array<RouteRecordRaw> = [
  { path: "/", redirect: "/login" },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(
        /* webpackChunkName: "auth" */ "../views/authentication/Login.vue"
      ),
    meta: { requiresAuth: false, requiresDesktop: false },
  },
  {
    path: "/auth/action",
    name: "AuthAction",
    component: () =>
      import(
        /* webpackChunkName: "auth" */ "../views/authentication/ActionHandler.vue"
      ),
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      const keys = Object.keys(to.query).length;

      if (keys >= 4) {
        next();
      } else {
        next({ path: "/", replace: true });
      }
    },
    meta: { isAuthAction: true },
  },
  {
    path: "/me",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/users/Profile.vue"),
    meta: {
      requiresAuth: true,
      requiresDesktop: false,
      requiredClearance: Clearance.Registered,
    },
  },
  {
    path: "/users",
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/users/Users.vue"),
    meta: {
      requiresAuth: true,
      requiresDesktop: false,
      requiredClearance: Clearance.Admin,
    },
  },
  {
    path: "/users/add-record",
    name: "AddUser",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/users/AddUser.vue"),
    meta: {
      requiresAuth: true,
      requiresDesktop: false,
      requiredClearance: Clearance.Admin,
    },
  },
  {
    path: "/users/edit-record",
    name: "EditUser",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/users/EditUser.vue"),
    meta: {
      requiresAuth: true,
      requiresDesktop: false,
      requiredClearance: Clearance.Admin,
    },
  },
  {
    path: "/banners",
    name: "Banners",
    component: () =>
      import(/* webpackChunkName: "banners" */ "../views/banners/Banners.vue"),
    meta: {
      requiresAuth: true,
      requiresDesktop: false,
      requiredClearance: Clearance.Editor,
    },
  },
  {
    path: "/banners/edit",
    name: "EditBanner",
    component: () =>
      import(
        /* webpackChunkName: "banners" */ "../views/banners/EditBanner.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresDesktop: false,
      requiredClearance: Clearance.Editor,
    },
  },
  {
    path: "/banners/add-new",
    name: "AddBanner",
    component: () =>
      import(
        /* webpackChunkName: "banners" */ "../views/banners/AddBanner.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresDesktop: false,
      requiredClearance: Clearance.Editor,
    },
  },
  {
    path: "/events",
    name: "Events",
    component: () =>
      import(/* webpackChunkName: "events" */ "../views/events/Events.vue"),
    meta: {
      requiresAuth: true,
      requiresDesktop: false,
      requiredClearance: Clearance.Editor,
    },
  },
  {
    path: "/events/new-weekly",
    name: "AddWeeklyEvent",
    component: () =>
      import(/* webpackChunkName: "events" */ "../views/events/weekly/Add.vue"),
    meta: {
      requiresAuth: true,
      requiresDesktop: false,
      requiredClearance: Clearance.Editor,
    },
  },
  {
    path: "/events/new-special",
    name: "AddSpecialEvent",
    component: () =>
      import(
        /* webpackChunkName: "events" */ "../views/events/special/Add.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresDesktop: false,
      requiredClearance: Clearance.Editor,
    },
  },
  {
    path: "/events/edit",
    name: "EditEvent",
    component: () =>
      import(/* webpackChunkName: "events" */ "../views/events/EditEvent.vue"),
    meta: {
      requiresAuth: true,
      requiresDesktop: false,
      requiredClearance: Clearance.Editor,
    },
  },
  {
    path: "/verse",
    name: "Verse",
    component: () =>
      import(/* webpackChunkName: "verse" */ "../views/verse/EditVerse.vue"),
    meta: {
      requiresAuth: true,
      requiresDesktop: false,
      requiredClearance: Clearance.Editor,
    },
  },
  {
    path: "/finance/records",
    name: "Donations",
    component: () =>
      import(/* webpackChunkName: "find-us" */ "../views/finance/Records.vue"),
    meta: {
      requiresAuth: true,
      requiresDesktop: true,
      requiredClearance: Clearance.Finance,
    },
  },
  {
    path: "/missions/projects",
    name: "Missions",
    component: () =>
      import(
        /* webpackChunkName: "missions" */ "../views/missions/Projects.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresDesktop: true,
      requiredClearance: Clearance.Editor,
    },
  },
  {
    path: "/missions/edit-project",
    name: "EditProject",
    component: () =>
      import(
        /* webpackChunkName: "missions" */ "../views/missions/EditProject.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresDesktop: true,
      requiredClearance: Clearance.Editor,
    },
  },
  {
    path: "/missions/new-project",
    name: "AddProject",
    component: () =>
      import(
        /* webpackChunkName: "missions" */ "../views/missions/EditProject.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresDesktop: true,
      requiredClearance: Clearance.Editor,
    },
  },
  {
    path: "/series/videos",
    name: "Series",
    component: () =>
      import(/* webpackChunkName: "series" */ "../views/series/Series.vue"),
    meta: {
      requiresAuth: true,
      requiresDesktop: true,
      requiredClearance: Clearance.Editor,
    },
  },
  {
    path: "/missions/edit-serie",
    name: "EditSerie",
    component: () =>
      import(/* webpackChunkName: "series" */ "../views/series/EditSeries.vue"),
    meta: {
      requiresAuth: true,
      requiresDesktop: true,
      requiredClearance: Clearance.Editor,
    },
  },
  {
    path: "/missions/new-serie",
    name: "AddSerie",
    component: () =>
      import(/* webpackChunkName: "series" */ "../views/series/EditSeries.vue"),
    meta: {
      requiresAuth: true,
      requiresDesktop: true,
      requiredClearance: Clearance.Editor,
    },
  },
  {
    path: "/find-us/venues",
    name: "Venues",
    component: () =>
      import(/* webpackChunkName: "find-us" */ "../views/find-us/Venues.vue"),
    meta: {
      requiresAuth: true,
      requiresDesktop: true,
      requiredClearance: Clearance.Editor,
    },
  },
  {
    path: "/find-us/messages",
    name: "Messages",
    component: () =>
      import(/* webpackChunkName: "find-us" */ "../views/find-us/Messages.vue"),
    meta: {
      requiresAuth: true,
      requiresDesktop: true,
      requiredClearance: Clearance.Manager,
    },
  },
  {
    path: "/forbidden",
    name: "Forbidden",
    component: () =>
      import(/* webpackChunkName: "find-us" */ "../views/errors/403.vue"),
    meta: { isError: true },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "find-us" */ "../views/errors/404.vue"),
    meta: { isError: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Authentication middleware
router.beforeEach(
  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    if (to.meta.isError || to.meta.isAuthAction) {
      next();
    } else {
      const authState = Authentication.authorizationState();

      switch (authState) {
        case AuthorizationState.NO_DATA:
          if (to.meta.requiresAuth) {
            Navigation.setPending(String(to.name));
          }
          Authentication.initialize();
          break;
        case AuthorizationState.INCOMPLETE:
          if (to.meta.requiresAuth) {
            next({ name: "Login" });
          } else {
            next();
          }
          break;
        case AuthorizationState.AUTHORIZED:
          if (!to.meta.requiresAuth) {
            next({ name: "Profile" });
          } else {
            next();
          }
          break;
        case AuthorizationState.NOT_AUTHORIZED:
          if (to.meta.requiresAuth) {
            next({ name: "Login" });
          } else {
            next();
          }
          break;
      }
    }
  }
);

// Authorization middleware
router.beforeEach(
  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    if (to.meta.requiresAuth) {
      const grantAcces = ClearanceController.validateClearance(
        to.meta.requiredClearance as Clearance
      );
      if (grantAcces) {
        next();
      } else {
        next({ name: "Forbidden" });
      }
    } else {
      next();
    }
  }
);

// Device Middleware
/* router.beforeEach(
  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    const isMobile =
      ResponsiveManager.retrieveScreenSize() === ScreenType.mobile;
    if (to.meta.requiresDesktop && isMobile) {
      
      next({ name: "Profile", replace: true });
    } else {
      next();
    }
  }
); */

export default router;
