<template>
  <div class="form-field columns">
    <label>{{ label }}</label>
    <select
      class="dropdown"
      v-model="value"
      ref="field"
      v-if="type === 'select'"
    >
      <option v-for="(value, index) in options" :key="index" :value="value">
        {{ value }}
      </option>
    </select>
    <div v-else-if="type === 'multiline'">
      <textarea
        class="textarea"
        :rows="rows"
        v-model="value"
        :readonly="readonly"
        @input="lenghtCheck"
        ref="field"
      />
      <span class="counter" v-if="maxlength">{{
        (maxlength ?? 0) - (value?.length ?? 0)
      }}</span>
    </div>
    <input
      class="input"
      :class="{
        centered: center,
      }"
      v-model="value"
      :type="type"
      :placeholder="placeholder"
      :readonly="readonly"
      @input="lenghtCheck"
      ref="field"
      v-else
    />
  </div>
</template>

<script lang="ts">
import { FieldType } from "@/models/common";
import { defineComponent, PropType } from "vue";
export default defineComponent({
  name: "BaseField",
  props: {
    modelValue: {
      type: [String, Number],
    },
    type: {
      type: String as PropType<FieldType>,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 5,
    },
    maxlength: {
      type: Number,
      default: undefined,
    },
    options: {
      type: Array as PropType<Array<string>>,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    focus: function () {
      (this.$refs.field as HTMLInputElement).focus();
    },
    lenghtCheck(event: Event): void {
      if (this.maxlength) {
        const inputValue = (event.target as HTMLInputElement).value;
        if (inputValue.length > this.maxlength) {
          this.value = inputValue.substring(0, this.maxlength);
          this.$emit("lengthExceeded");
        }
      }
    },
  },
  emits: ["update:modelValue", "lengthExceeded"],
  computed: {
    value: {
      // eslint-disable-next-line
      get(): any {
        return this.modelValue;
      },
      set(value: string | number | undefined): void {
        this.$emit("update:modelValue", value);
      },
    },
  },
});
</script>

<style scoped>
textarea {
  resize: none;
}
.centered {
  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-field {
  font-size: 1rem;
  width: 100%;
}
.form-field label {
  font-size: 1.6em;
  font-weight: var(--f-semibold);
  margin-bottom: 0.3em;
  display: block;
}
.form-field .input,
.form-field .textarea,
.form-field .dropdown {
  font-size: 1.4em;
  font-weight: var(--f-regular);
  font-family: "Montserrat", sans-serif;
  border-radius: 8px;
  background: var(--c-concrete);
  border: none;
  outline: none;
  box-sizing: border-box;
  padding: 1em;
  height: 4.8rem;
  width: 100%;
}

.form-field .textarea {
  height: auto;
}

@media only screen and (min-width: 1025px) {
  .field-label {
    margin-bottom: 6px;
  }
}
</style>
