// Custom Types

export type FieldType =
  | "text"
  | "number"
  | "email"
  | "select"
  | "password"
  | "multiline";

export enum ScreenType {
  largeDesktop = 1,
  smallDesktop,
  tablet,
  mobile,
}

export enum SelectionType {
  None,
  Image,
  Video,
}

export enum SelectionState {
  TooBig = 1,
  NotValid,
  Image,
  Video,
}

export interface NavState {
  opened: boolean;
  device: ScreenType;
}

export interface LoginData {
  email: string;
  password: string;
}

export interface SelectionValue {
  file: File | undefined;
  url: string;
}
