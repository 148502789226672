
import { FieldType } from "@/models/common";
import { defineComponent, PropType } from "vue";
export default defineComponent({
  name: "BaseField",
  props: {
    modelValue: {
      type: [String, Number],
    },
    type: {
      type: String as PropType<FieldType>,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 5,
    },
    maxlength: {
      type: Number,
      default: undefined,
    },
    options: {
      type: Array as PropType<Array<string>>,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    focus: function () {
      (this.$refs.field as HTMLInputElement).focus();
    },
    lenghtCheck(event: Event): void {
      if (this.maxlength) {
        const inputValue = (event.target as HTMLInputElement).value;
        if (inputValue.length > this.maxlength) {
          this.value = inputValue.substring(0, this.maxlength);
          this.$emit("lengthExceeded");
        }
      }
    },
  },
  emits: ["update:modelValue", "lengthExceeded"],
  computed: {
    value: {
      // eslint-disable-next-line
      get(): any {
        return this.modelValue;
      },
      set(value: string | number | undefined): void {
        this.$emit("update:modelValue", value);
      },
    },
  },
});
