export enum AuthorizationState {
  NO_DATA,
  INCOMPLETE,
  AUTHENTICATED,
  AUTHORIZED,
  NOT_AUTHORIZED,
}

export interface TFAData {
  uid: string;
  token: string;
}
