import firebase from "firebase/app";

export type FirebaseUser = firebase.User | null;

export interface CredentialsData {
  email: string;
  password: string;
}

export enum Clearance {
  Registered = "registered",
  Finance = "finance",
  Editor = "editor",
  Manager = "manager",
  Admin = "administrator",
}

export interface ClearanceData {
  email: string;
  clearance: Clearance;
}

export interface UserData {
  name: string;
  lastname: string;
  phone: number;
  email: string;
  role?: Clearance;
  picture?: File;
  pictureUrl: string;
  disabled: boolean;
}

export default class User {
  name: string;
  lastname: string;
  phone: number;
  email: string;
  role: Clearance;
  picture: File | undefined;
  pictureUrl: string;
  disabled: boolean;

  constructor({
    name,
    lastname,
    phone,
    email,
    role = Clearance.Registered,
    picture,
    pictureUrl,
    disabled,
  }: UserData) {
    this.name = name;
    this.lastname = lastname;
    this.phone = phone;
    this.email = email;
    this.role = role;
    this.picture = picture;
    this.pictureUrl = pictureUrl;
    this.disabled = disabled;
  }

  get json(): UserData {
    return {
      name: this.name,
      lastname: this.lastname,
      phone: this.phone,
      email: this.email,
      role: this.role,
      pictureUrl: this.pictureUrl,
      disabled: this.disabled,
    } as UserData;
  }
}
