
import { TFAData } from "@/models/auth";
import { defineComponent } from "vue";
import BaseField from "@/components/common/BaseField.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  name: "TFAuthentication",
  components: {
    BaseField,
    BaseButton,
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    reset: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  mounted() {
    this.focusField();
  },
  data() {
    return {
      fieldIndex: 0,
      keys: ["A", "B", "C", "D", "E", "F"],
      states: [true, true, true, true, true, true],
      values: ["", "", "", "", "", ""],
      data: {
        uid: this.$props.uid,
      } as TFAData,
    };
  },
  watch: {
    reset: function () {
      if (this.reset && this.loading) {
        for (let index = 0; index < 6; index++) {
          this.states[index] = true;
          this.values[index] = "";
        }
        this.fieldIndex = 0;
        this.focusField();
        this.$emit("cleared");
      }
    },
    values: {
      deep: true,
      handler(): void {
        if (!this.loading) {
          if (this.values[this.fieldIndex]) {
            this.toNextField();
          } else {
            this.toPreviousField();
          }
        }
      },
    },
  },
  emits: ["verifyToken", "resetSecret", "cleared"],
  computed: {
    formattedToken(): string {
      return Object.values(this.values).join("");
    },
    validForm(): boolean {
      return !this.v$.$invalid;
    },
  },
  methods: {
    emptyDelete(): void {
      this.toPreviousField();
    },
    fullField(): void {
      this.toNextField();
    },
    toNextField(): void {
      if (this.fieldIndex < 5) {
        this.states[this.fieldIndex++] = true;
        this.focusField();
      }
    },
    toPreviousField(): void {
      if (!this.values[this.fieldIndex] && this.fieldIndex > 0) {
        this.states[this.fieldIndex--] = true;
        this.focusField();
      }
    },
    focusField(): void {
      this.states[this.fieldIndex] = false;
      (
        this.$refs[`input${this.keys[this.fieldIndex]}`] as HTMLInputElement
      ).focus();
    },
    verifyToken(event: Event): void {
      event.preventDefault();
      this.data.token = this.formattedToken;
      this.$emit("verifyToken", this.data);
    },
    reset2FA(event: Event): void {
      event.preventDefault();
      this.$emit("resetSecret");
    },
  },
  validations() {
    const validToken = () => {
      return (
        this.values.length === 6 && this.values.every((digit) => digit.length)
      );
    };
    return {
      data: {
        uid: { required },
      },
      values: {
        validToken,
      },
    };
  },
});
