
import { defineComponent } from "vue";

export default defineComponent({
  name: "NavigatorItem",
  props: {
    isDesktop: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  watch: {
    // eslint-disable-next-line
    $route(to, from) {
      this.isOpen = false;
    },
  },
  methods: {
    handleHover(isOver: boolean) {
      if (this.isDesktop) {
        this.isOpen = isOver;
      }
    },
    handleTap(event: Event) {
      event.preventDefault();
      if (!this.isDesktop) {
        this.isOpen = !this.isOpen;
      }
    },
  },
});
