<template>
  <a-spin :spinning="loading" :delay="250">
    <form class="cc-form">
      <div class="cc-logo all columns">
        <img
          src="@/assets/common/centro-cristiano.svg"
          alt="Iglesia Centro Cristiano"
        />
      </div>
      <div class="all columns">
        <h2 class="global-title greet">Ingresa con tu código de seguridad</h2>
      </div>
      <div class="tfa-code all columns">
        <BaseField
          v-model="values[index]"
          type="number"
          placeholder="o"
          :center="true"
          :maxlength="1"
          :readonly="states[index]"
          :ref="`input${keys[index]}`"
          v-for="(value, index) in values"
          :key="index"
          @keyup.delete="emptyDelete"
          @length-exceeded="fullField"
        />
      </div>
      <BaseButton
        text="Continuar"
        class="all columns"
        :disabled="!validForm"
        @click="verifyToken"
      />
      <a class="all columns reset-opt" href="#" @click="reset2FA"
        >Renovar Secreto</a
      >
      <div></div>
    </form>
  </a-spin>
</template>

<script lang="ts">
import { TFAData } from "@/models/auth";
import { defineComponent } from "vue";
import BaseField from "@/components/common/BaseField.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  name: "TFAuthentication",
  components: {
    BaseField,
    BaseButton,
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    reset: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  mounted() {
    this.focusField();
  },
  data() {
    return {
      fieldIndex: 0,
      keys: ["A", "B", "C", "D", "E", "F"],
      states: [true, true, true, true, true, true],
      values: ["", "", "", "", "", ""],
      data: {
        uid: this.$props.uid,
      } as TFAData,
    };
  },
  watch: {
    reset: function () {
      if (this.reset && this.loading) {
        for (let index = 0; index < 6; index++) {
          this.states[index] = true;
          this.values[index] = "";
        }
        this.fieldIndex = 0;
        this.focusField();
        this.$emit("cleared");
      }
    },
    values: {
      deep: true,
      handler(): void {
        if (!this.loading) {
          if (this.values[this.fieldIndex]) {
            this.toNextField();
          } else {
            this.toPreviousField();
          }
        }
      },
    },
  },
  emits: ["verifyToken", "resetSecret", "cleared"],
  computed: {
    formattedToken(): string {
      return Object.values(this.values).join("");
    },
    validForm(): boolean {
      return !this.v$.$invalid;
    },
  },
  methods: {
    emptyDelete(): void {
      this.toPreviousField();
    },
    fullField(): void {
      this.toNextField();
    },
    toNextField(): void {
      if (this.fieldIndex < 5) {
        this.states[this.fieldIndex++] = true;
        this.focusField();
      }
    },
    toPreviousField(): void {
      if (!this.values[this.fieldIndex] && this.fieldIndex > 0) {
        this.states[this.fieldIndex--] = true;
        this.focusField();
      }
    },
    focusField(): void {
      this.states[this.fieldIndex] = false;
      (
        this.$refs[`input${this.keys[this.fieldIndex]}`] as HTMLInputElement
      ).focus();
    },
    verifyToken(event: Event): void {
      event.preventDefault();
      this.data.token = this.formattedToken;
      this.$emit("verifyToken", this.data);
    },
    reset2FA(event: Event): void {
      event.preventDefault();
      this.$emit("resetSecret");
    },
  },
  validations() {
    const validToken = () => {
      return (
        this.values.length === 6 && this.values.every((digit) => digit.length)
      );
    };
    return {
      data: {
        uid: { required },
      },
      values: {
        validToken,
      },
    };
  },
});
</script>

<style scoped>
.cc-logo {
  display: flex;
  justify-content: center;
}

.cc-logo img {
  height: 6rem;
  width: auto;
}

.global-title {
  text-align: center;
  margin: 0;
}

.greet {
  font-size: 2rem;
}

.description {
  font-size: 1.4rem;
}

.tfa-code {
  grid-template-columns: repeat(6, 5rem);
  justify-content: center;
  column-gap: 1rem;
  display: grid;
}

.reset-opt {
  text-align: center;
  font-size: 1.4rem;
  color: var(--c-mid-gray);
}

.reset-opt:hover {
  color: var(--c-harvest-gold);
  text-decoration: underline;
}
</style>
