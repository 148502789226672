import { Store } from "@/controllers/common";
import { AuthorizationState } from "@/models/auth";
import { FirebaseUser } from "@/models/users";
import Firebase from "./firebase";
import FirebaseAuth from "./firebase-auth";

export default class Authentication {
  static currentUser(): FirebaseUser {
    return Store.getValue("auth/currentUser");
  }

  static isAuthenticated(): boolean | null {
    return Store.getValue("auth/isAuthenticated");
  }

  static isVerified(): boolean | null {
    return Store.getValue("auth/isVerified");
  }

  static authorizationState(): AuthorizationState {
    return Store.getValue("auth/isAuthorized");
  }

  static check(
    authentication: boolean | null,
    tfa: boolean | null
  ): AuthorizationState {
    if (authentication === true && tfa === true) {
      return AuthorizationState.AUTHORIZED;
    } else if (authentication === true && tfa === null) {
      return AuthorizationState.AUTHENTICATED;
    } else if (authentication === false || tfa === false) {
      return AuthorizationState.INCOMPLETE;
    } else if (authentication === null) {
      return AuthorizationState.NO_DATA;
    } else {
      return AuthorizationState.NOT_AUTHORIZED;
    }
  }

  static initialize(): void {
    Firebase.initialize();
    FirebaseAuth.addAuthListener();
  }
}
