<template>
  <nav class="global-nav masked-nav" :class="{ opened: isMenuOpen }">
    <NavigatorMobile v-model="isMenuOpen" :state="state" v-if="showMobile" />
    <NavigatorAdmin :currentUser="userData" :state="state" />
  </nav>
  <div class="global-nav glass-background" :class="{ opened: isMenuOpen }" />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import NavigatorMobile from "@/components/navigation/Mobile.vue";
import NavigatorAdmin from "@/components/navigation/Admin.vue";

import { NavState, ScreenType } from "@/models/common";
import { ResponsiveManager } from "@/controllers/common";
import { FirebaseUser, UserData } from "@/models/users";
import { ClearanceController } from "@/controllers/clearance";

export default defineComponent({
  name: "NavigatorCommon",
  components: {
    NavigatorMobile,
    NavigatorAdmin,
  },
  props: {
    currentUser: {
      type: Object as PropType<FirebaseUser>,
      default: null,
    },
  },
  mounted() {
    if (this.currentUser) {
      const displayName = this.currentUser?.displayName?.split(";") ?? ["", ""];
      this.userData.name = displayName[0];
      this.userData.lastname = displayName[1];
      this.userData.email = this.currentUser?.email ?? "";
      this.userData.phone = +(this.currentUser?.phoneNumber ?? "").replace(
        "57",
        ""
      );
      this.userData.pictureUrl = this.currentUser?.photoURL ?? "";
      this.getCurrentRole(this.currentUser);
    }
  },
  data() {
    return {
      userData: {} as UserData,
      isMenuOpen: false,
    };
  },
  watch: {
    // eslint-disable-next-line
    $route(to, from) {
      this.isMenuOpen = false;
    },
    showMobile: function () {
      if (!this.showMobile) {
        this.isMenuOpen = false;
      }
    },
  },
  computed: {
    showMobile(): boolean {
      return ResponsiveManager.retrieveScreenSize() != ScreenType.largeDesktop;
    },
    state(): NavState {
      return {
        opened: this.isMenuOpen,
        device: ResponsiveManager.retrieveScreenSize(),
      };
    },
  },
  methods: {
    async getCurrentRole(currentUser: FirebaseUser) {
      if (currentUser) {
        this.userData.role = ClearanceController.granted;
      }
    },
  },
});
</script>

<style scoped>
.global-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  max-width: 100vw;
  height: var(--nav-mobile-height);
  transition: 0.3s ease-in-out;
}
.global-nav.opened {
  height: 100vh;
}
.glass-background {
  z-index: 998;
  backdrop-filter: blur(10px);
  --webkit-backdrop-filter: blur(10px);
}
nav {
  z-index: 999;
  background-color: var(--c-eighty-black);
}
nav.opened {
  background-color: var(--c-ninety-five-black);
}

.masked-nav.opened {
  --nav-width: 100vw;
  background-attachment: fixed;
  background-image: url("../../assets/common/mobile-menu-mask.png");
  background-position: left 0 bottom 0;
  background-size: var(--nav-width) auto;
  background-repeat: no-repeat;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.no-select {
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
}

/* Admin nav style */
@media only screen and (min-width: 768px) {
  .global-nav {
    right: initial;
    bottom: 0;
    width: var(--nav-tablet-width);
    height: auto;
    display: flex;
  }
  .global-nav.opened {
    width: calc(var(--nav-tablet-width) + var(--nav-min-menu-width));
    background-color: var(--c-ninety-five-black);
  }

  .masked-nav.opened {
    --nav-width: calc(var(--nav-tablet-width) + var(--nav-min-menu-width));
  }
}
@media only screen and (min-width: 1280px) {
  .masked-nav {
    background-attachment: fixed;
    background-image: url("../../assets/common/drawer-mask.png");
    background-position: left 0 bottom 0;
    background-size: max(var(--nav-min-menu-width), 25vw) auto;
    background-repeat: no-repeat;
  }

  .global-nav {
    width: max(var(--nav-min-menu-width), 25vw);
    display: block;
  }
}
@media only screen and (min-width: 1440px) {
  .masked-nav {
    background-size: max(var(--nav-min-menu-width), 18.25vw) auto;
  }

  .global-nav {
    width: max(var(--nav-min-menu-width), 18.25vw);
  }
}
</style>
