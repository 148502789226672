<template>
  <li class="admin-route">
    <router-link :to="{ name: target }">{{ title }}</router-link>
  </li>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "NavigatorItem",
  props: {
    isDesktop: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  watch: {
    // eslint-disable-next-line
    $route(to, from) {
      this.isOpen = false;
    },
  },
  methods: {
    handleHover(isOver: boolean) {
      if (this.isDesktop) {
        this.isOpen = isOver;
      }
    },
    handleTap(event: Event) {
      event.preventDefault();
      if (!this.isDesktop) {
        this.isOpen = !this.isOpen;
      }
    },
  },
});
</script>

<style scoped>
a {
  color: #949494;
  font-weight: var(--f-medium);
  font-size: 1.4rem;
  text-decoration: none;
}
a:hover,
a.router-link-exact-active {
  color: var(--c-harvest-gold);
}
@media only screen and (max-width: 1023px) {
  li {
    text-align: start;
  }
  a {
    width: 100%;
    padding: 12px 0;
    display: block;
  }
}
@media only screen and (min-width: 1024px) {
  li {
    align-items: center;
    display: flex;
    text-align: center;
  }
  a,
  div {
    text-align: center;
    font-weight: var(--f-medium);
  }
  /* Admin Style */
  .admin-route {
    border: none;
  }
  .admin-route a {
    text-align: start;
    width: calc(100% - 2rem);
    padding: 0.5rem 0;
    margin-left: 2rem;
  }
}
</style>
