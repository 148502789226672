import { createStore, Module } from "vuex";
import { initialScreenSize } from "@/controllers/common";
import { ScreenType } from "@/models/common";
import Project from "@/models/project";
import Serie from "@/models/serie";
import { AuthModule, EditionModule, MainModule } from "@/models/store-modules";
import User, { Clearance, FirebaseUser } from "@/models/users";
import { AuthorizationState } from "@/models/auth";
import Authentication from "@/controllers/auth";
import CCEvent from "@/models/event";
import Banner from "@/models/banner";

const authModule: Module<AuthModule, MainModule> = {
  namespaced: true,
  state: () => ({
    currentUser: null,
    clearance: null,
    authorized: AuthorizationState.NO_DATA,
    loggedIn: null,
    tfa: null,
  }),
  getters: {
    currentUser(state) {
      return state.currentUser;
    },
    grantedClearance(state) {
      return state.clearance;
    },
    isAuthenticated(state) {
      return state.loggedIn;
    },
    isVerified(state) {
      return state.tfa;
    },
    isAuthorized(state) {
      return state.authorized;
    },
  },
  mutations: {
    updateAuth(state, user: FirebaseUser): void {
      state.currentUser = user;
      state.loggedIn = user != null;
      state.authorized = Authentication.check(state.loggedIn, state.tfa);
      console.log(
        `Updated Auth, Auth Status:
        loggedIn: ${state.loggedIn}
        2fa: ${state.tfa}
        auth: ${state.authorized}`
      );
    },
    updateClearance(state, clearance: Clearance | null): void {
      state.clearance = clearance;
    },
    update2FA(state, isAuthorized: boolean | null): void {
      state.tfa = isAuthorized;
      state.authorized = Authentication.check(state.loggedIn, state.tfa);
      console.log(
        `Updated 2FA, Auth Status:
        loggedIn: ${state.loggedIn}
        2fa: ${state.tfa}
        auth: ${state.authorized}`
      );
    },
  },
  actions: {
    changeAuth(context, user: FirebaseUser): void {
      context.commit("updateAuth", user);
      if (user) {
        user.getIdTokenResult(true).then((idTokenResult) => {
          const granted: Clearance = idTokenResult.claims.clearance;
          context.commit("updateClearance", granted);
        });
      } else {
        context.commit("updateClearance", null);
      }
    },
    change2FA(context, state: boolean | null): void {
      context.commit("update2FA", state);
    },
  },
};

const editionModule: Module<EditionModule, MainModule> = {
  namespaced: true,
  state: () => ({
    banner: undefined,
    event: undefined,
    project: undefined,
    serie: undefined,
    user: undefined,
  }),
  getters: {
    event(state) {
      return state.event;
    },
    banner(state) {
      return state.banner;
    },
    project(state) {
      return state.project;
    },
    serie(state) {
      return state.serie;
    },
    user(state) {
      return state.user;
    },
  },
  mutations: {
    updateBanner(state, banner: Banner | undefined): void {
      state.banner = banner;
    },
    updateEvent(state, event: CCEvent | undefined): void {
      state.event = event;
    },
    updateProject(state, project: Project | undefined): void {
      state.project = project;
    },
    updateSerie(state, serie: Serie | undefined): void {
      state.serie = serie;
    },
    updateUser(state, user: User | undefined): void {
      state.user = user;
    },
  },
  actions: {
    storeBanner(context, banner: Banner): void {
      context.commit("updateBanner", banner);
    },
    storeEvent(context, event: CCEvent): void {
      context.commit("updateEvent", event);
    },
    storeProject(context, project: Project): void {
      context.commit("updateProject", project);
    },
    storeSerie(context, serie: Serie): void {
      context.commit("updateSerie", serie);
    },
    storeUser(context, user: User): void {
      context.commit("updateUser", user);
    },
  },
};

export default createStore<MainModule>({
  state: {
    device: initialScreenSize(),
    route: null,
    prodHost: "https://prod.vps.igle.cc",
    devHost: "https://cc-backend-development.herokuapp.com",
    // devHost: "https://centro-cristiano-backend.herokuapp.com",
    // devHost: "http://192.168.1.7:3005",
  },
  getters: {
    deviceType(state) {
      return state.device;
    },
    pendingRoute(state) {
      return state.route;
    },
    productionHost(state) {
      return state.prodHost;
    },
    developmentHost(state) {
      return state.prodHost;
    },
  },
  mutations: {
    updateDevice(state, device: ScreenType): void {
      state.device = device;
    },
    updateRoute(state, route: string | null) {
      state.route = route;
    },
  },
  actions: {
    changeDevice(context, device: ScreenType): void {
      context.commit("updateDevice", device);
    },
    setPendingRoute(context, route: string | null): void {
      context.commit("updateRoute", route);
    },
    setProccessState(context, loading: boolean): void {
      context.commit("updateProcessing", loading);
    },
  },
  modules: {
    auth: authModule,
    edition: editionModule,
  },
});
