import { Clearance } from "@/models/users";
import { Store } from "./common";

export class ClearanceController {
  private static level(clearance: Clearance): number {
    switch (clearance) {
      case Clearance.Admin:
        return 4;
      case Clearance.Manager:
        return 2;
      case Clearance.Finance:
        return 1;
      case Clearance.Editor:
        return 1;
      default:
        return 0;
    }
  }

  static get granted(): Clearance {
    const clearance = Store.getValue("auth/grantedClearance");
    if (clearance != null) {
      return clearance;
    } else {
      return Clearance.Registered;
    }
  }

  static validateClearance(required: Clearance): boolean {
    const granted = ClearanceController.granted;
    const enough = required === granted;
    const higher =
      ClearanceController.level(granted) > ClearanceController.level(required);
    return enough || higher;
  }

  static text(clearance?: Clearance): string {
    switch (clearance) {
      case "finance":
        return "Representante de Finanzas";
      case "editor":
        return "Editor de Contenido";
      case "manager":
        return "Gestor de Contenido";
      case "administrator":
        return "Administrador";
      default:
        return "Registrado";
    }
  }
}
