import PopUpMessage, { NotificationType } from "@/models/popup";
import { AxiosError } from "axios";
import { Store } from "./common";

export default abstract class ServiceController {
  protected static readonly HOST = Store.getValue("developmentHost");

  protected static translateError(error: AxiosError): void {
    let notificationTitle = "Error en la solicitud";
    let notificationMessage: string;
    switch (error.response?.status) {
      case 400:
        notificationMessage =
          "Hubo un error con los datos enviados, verificalos en intenta de nuevo.";
        break;
      case 401:
        notificationTitle = "Credenciales inválidas";
        notificationMessage =
          "Hubo un error verificando tus credenciales, vuelve a autenticarte.";
        break;
      case 403:
        notificationTitle = "Permisos insuficientes";
        notificationMessage =
          "No cuentas con los permisos adecuados para realizar esta operación.";
        break;
      case 404:
        notificationTitle = "No encontrado";
        notificationMessage = "No se encuentra el recurso solicitado";
        break;
      default:
        notificationMessage =
          "Hubo un error en el servidor. Intentalo de nuevo o contacta a soporte.";
        break;
    }
    const notification = new PopUpMessage({
      title: notificationTitle,
      message: notificationMessage,
      type: NotificationType.Error,
    });
    notification.show();
  }
}
