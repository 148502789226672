import { DispatchOptions } from "vuex";
import { RouteParamsRaw } from "vue-router";
import { ScreenType } from "@/models/common";
import router from "@/router";
import store from "@/store";

export function initialScreenSize(): ScreenType {
  const width = window.innerWidth;
  if (width >= 1280) return ScreenType.largeDesktop;
  if (width >= 1025) return ScreenType.smallDesktop;
  if (width >= 768) return ScreenType.tablet;
  return ScreenType.mobile;
}

export abstract class ResponsiveManager {
  static screenSize(): ScreenType {
    const width = window.innerWidth;
    if (width >= 1280) return ScreenType.largeDesktop;
    if (width >= 1024) return ScreenType.smallDesktop;
    if (width >= 768) return ScreenType.tablet;
    return ScreenType.mobile;
  }

  static storeScreenSize(): void {
    Store.update("changeDevice", ResponsiveManager.screenSize());
  }

  static retrieveScreenSize(): ScreenType {
    return Store.getValue("deviceType");
  }
}

export abstract class Navigation {
  static goTo(target: string, params?: RouteParamsRaw): void {
    router.push({ name: target, params });
  }

  static goBack(): void {
    router.go(-1);
  }

  static setPending(routeName: string): void {
    Store.update("setPendingRoute", routeName);
  }

  static clearPending(): void {
    Store.update("setPendingRoute", null);
  }

  static get pendingRoute(): string | null {
    const route: string | null = Store.getValue("pendingRoute");
    Store.update("setPendingRoute", null);
    return route;
  }
}

export abstract class Store {
  static update(
    name: string,
    // eslint-disable-next-line
    payload?: any,
    options?: DispatchOptions | undefined
  ): void {
    store.dispatch(name, payload, options);
  }

  // eslint-disable-next-line
  static getValue(name: string): any {
    return store.getters[name];
  }
}
