import { ChannelsClient } from "@/models/pusher";
import Pusher, { Channel } from "pusher-js";

export default class Channels {
  private readonly _pusher: Pusher;
  private _channel!: Channel;

  constructor() {
    this._pusher = new Pusher("30cd398f20aecf41d702", {
      cluster: "us2",
    });
  }

  // eslint-disable-next-line
  addAuthListener(client: ChannelsClient, observer: Function): void {
    this._channel = this._pusher.subscribe(client.channel);
    this._channel.bind(client.event, observer);
  }

  disconnect(): void {
    if (this._channel != null) {
      this._channel.disconnect();
      this._pusher.disconnect();
    }
  }
}
