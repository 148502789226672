
import { defineComponent } from "vue";
export default defineComponent({
  name: "BaseButton",
  props: {
    text: {
      type: String,
      required: true,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
