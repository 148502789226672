import PopUpMessage, { NotificationType, PopUpData } from "@/models/popup";
import { CredentialsData, FirebaseUser } from "@/models/users";
import firebase from "firebase/app";
import { Store } from "./common";

export default class FirebaseAuth {
  // eslint-disable-next-line
  private static translateError(error: any): PopUpMessage | undefined {
    const popupConfig: PopUpData = {
      type: NotificationType.Error,
    } as PopUpData;
    switch (error.code) {
      case "auth/invalid-email":
        popupConfig.title = "Correo electrónico inválido";
        popupConfig.message =
          "La dirección de correo proporcionada no es válida.";
        return new PopUpMessage(popupConfig);
      case "auth/user-disabled":
        popupConfig.title = "Usuario desactivado";
        popupConfig.message =
          "La cuenta de usuario ha sido deshabilitada por un administrador.";
        return new PopUpMessage(popupConfig);
      case "auth/user-not-found":
        popupConfig.title = "Usuario no encontrado";
        popupConfig.message =
          "No hay ningún usuario que se corresponda con el correo electrónico dado.";
        return new PopUpMessage(popupConfig);
      case "auth/wrong-password":
        popupConfig.title = "Contraseña incorrecta";
        popupConfig.message =
          "La contraseña no es válida para el correo electrónico dado.";
        return new PopUpMessage(popupConfig);
      default:
        return undefined;
    }
  }

  static async signIn(credentials: CredentialsData): Promise<void> {
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(credentials.email, credentials.password);
      // eslint-disable-next-line
    } catch (error: any) {
      const notification =
        this.translateError(error) ??
        new PopUpMessage({
          title: error.code,
          message: error.message,
          type: NotificationType.Error,
        });
      notification.show();
    }
  }

  static async signOut(): Promise<void> {
    try {
      await firebase.auth().signOut();
      // eslint-disable-next-line
    } catch (error: any) {
      const notification = new PopUpMessage({
        title: error.code,
        message: error.message,
        type: NotificationType.Error,
      });
      notification.show();
    }
  }

  static addAuthListener(): void {
    firebase.auth().onAuthStateChanged((user: FirebaseUser) => {
      Store.update("auth/changeAuth", user);
    });
  }

  static async checkActionCode(
    auth: firebase.auth.Auth,
    actionCode: string
  ): Promise<string | undefined> {
    try {
      const email = await auth.verifyPasswordResetCode(actionCode);
      return email;
    } catch (error) {
      const notification = new PopUpMessage({
        title: error.code,
        message: "Intenta solicitar el cambio de contraseña de nuevo.",
        type: NotificationType.Error,
      });
      notification.show();
    }
  }

  static async confirmPasswordReset(
    auth: firebase.auth.Auth,
    actionCode: string,
    newPassword: string
  ): Promise<boolean> {
    try {
      await auth.confirmPasswordReset(actionCode, newPassword);
      return true;
    } catch (error) {
      const notification = new PopUpMessage({
        title: error.code,
        message: error.message,
        type: NotificationType.Error,
      });
      notification.show();
      return false;
    }
  }
}
